import APIClient, { endpoints } from 'shared/api';
import { ActionType, DispatchFunction } from 'types/action';
import {
  IDriverModel,
  ILoginModel,
  IUserModel,
  IUserStatusModel,
} from 'types/user';
import { CmtCountries, CountryCode, Rol } from 'types/common';
import PATH_ROUTES from 'static/constants/path-routes';
import { decodeToken } from 'react-jwt';

export const getCurrentCognitoUser = async (): Promise<any | null> => {
  const idToken = localStorage.getItem('idToken');
  const accessToken = localStorage.getItem('accessToken');

  if (idToken && accessToken) {
    const decodedToken: any = decodeToken(idToken);
    const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
    const isExpire = decodedToken && decodedToken.exp > currentTime;

    try {
      const isValidCurrentSession = await APIClient.post(
        endpoints.POST_AUTH_VALIDATE_SESSION,
        {
          sub: decodedToken?.sub,
          accessToken: accessToken,
        },
      );

      return {
        idToken,
        accessToken,
        isAuthenticate:
          isValidCurrentSession && isValidCurrentSession.data.data.success,
        sessionExpired: isExpire,
      };
    } catch (error) {
      return {
        idToken,
        accessToken,
        isAuthenticate: false,
        sessionExpired: false,
      };
    }
  } else {
    return {
      idToken: null,
      accessToken: null,
      isAuthenticate: false,
      sessionExpired: false,
    };
  }
};

//Request Authentication
const requestClaimsAuth = async (
  username: string,
  password: string,
  mfaCode?: string,
): Promise<any> => {
  return await APIClient.post(endpoints.POST_AUTH_USER, {
    username: username,
    password: password,
    mfaCode: mfaCode,
  });
};

// Request User Information
const requestUserData = async (guid: string): Promise<any> => {
  const endpointWithData = endpoints.GET_USER.replace('{guid}', guid);

  return await APIClient.get(endpointWithData);
};

export const requestSignOut = async (): Promise<void> => {
  const accessToken = localStorage.getItem('accessToken');
  try {
    if (accessToken) {
      await APIClient.post(endpoints.POST_AUTH_SIGN_OUT, {
        accessToken: accessToken,
      });
    } else {
      console.warn('SignOut :: Access Token not found');
    }
  } catch (error) {
    console.warn(error);
  }
};

// Authorize from SIC
export const getAuthorize = async (
  user: ILoginModel,
  dispatch: DispatchFunction,
): Promise<void> => {
  dispatch({ type: ActionType.REQUEST_SIGN_IN });

  try {
    const result = await APIClient.post(endpoints.GET_AUTHORIZE, user);
    if (result.data.success) {
      const newUserStatus = {
        isError: false,
        isLogin: true,
        message: 'Access granted',
        token: result.data.data.guidID,
        country: result.data.data.codPais,
      };
      dispatch({ type: ActionType.SIGN_IN_SUCCESS });
      dispatch({ type: ActionType.SET_USER_STATUS, payload: newUserStatus });
    }
  } catch (error: any) {
    if (!error && error.success) {
      const newUserStatus = {
        isError: true,
        isLogin: false,
        message: 'User does not correspond to any account',
      };
      dispatch({ type: ActionType.SIGN_IN_FAIL });
      dispatch({ type: ActionType.SET_USER_STATUS, payload: newUserStatus });
    }
  }
};

// Authorize from Claims
export const claimsAuth = async (
  user: ILoginModel,
  dispatch: DispatchFunction,
): Promise<void> => {
  dispatch({ type: ActionType.REQUEST_SIGN_IN });
  try {
    const requestAuth = await requestClaimsAuth(user.username, user.password);
    const authData = requestAuth.data.data;
    if (
      requestAuth.data.success &&
      authData &&
      authData.isAuthenticated === true
    ) {
      const userGuid = authData.sub;
      const newUserStatus = {
        isError: false,
        isLogin: true,
        message: 'Access granted',
        token: authData.idToken,
        country: 'PRI',
        role: '',
        guid: userGuid,
      };

      (async (): Promise<void> => {
        const result = await requestUserData(userGuid);

        if (result && result.data?.data) {
          const isUserActive = result.data?.data?.active ?? true;

          if (isUserActive) {
            localStorage.setItem('idToken', authData.idToken);
            localStorage.setItem('accessToken', authData.accessToken);
            localStorage.setItem('refreshToken', authData.refreshToken);

            dispatch({ type: ActionType.SIGN_IN_SUCCESS });
            dispatch({
              type: ActionType.SET_USER_STATUS,
              payload: newUserStatus,
            });
          } else {
            dispatch({ type: ActionType.SIGN_IN_FAIL });
            dispatch({
              type: ActionType.SET_USER_STATUS,
              payload: {
                isError: true,
                isLogin: false,
                message:
                  'Usuario inactivo. Por favor consulte a un administrativo.',
              },
            });
          }
        } else {
          dispatch({ type: ActionType.SIGN_IN_FAIL });
          dispatch({
            type: ActionType.SET_USER_STATUS,
            payload: {
              isError: true,
              isLogin: false,
              message: 'User does not correspond to any account',
            },
          });
        }
      })();
    } else {
      const newUserStatus = {
        isError: true,
        isLogin: false,
        message: 'User does not correspond to any account',
      };
      dispatch({ type: ActionType.SIGN_IN_FAIL });
      dispatch({
        type: ActionType.SET_USER_STATUS,
        payload: newUserStatus,
      });
    }
  } catch (error: any) {
    if (!error && error.success) {
      const newUserStatus = {
        isError: true,
        isLogin: false,
        message: 'User does not correspond to any account',
      };
      dispatch({ type: ActionType.SIGN_IN_FAIL });
      dispatch({ type: ActionType.SET_USER_STATUS, payload: newUserStatus });
    } else {
      const errorRes = error.response.data;
      if (errorRes && errorRes?.error?.code === 'UserNotConfirmedException') {
        dispatch({
          type: ActionType.SET_USER_NEW_PASSWORD,
          payload: {
            confirmAccount: true,
            isNewUser: false,
            sessionAttr: { email: user.username },
          },
        });
      } else {
        const newUserStatus = {
          isError: true,
          isLogin: false,
          message:
            errorRes && errorRes?.error
              ? errorRes.error.message
              : 'User does not correspond to any account',
        };
        dispatch({ type: ActionType.SIGN_IN_FAIL });
        dispatch({
          type: ActionType.SET_USER_STATUS,
          payload: newUserStatus,
        });
      }
    }
  }
};

// // Authorize from Cognito
// export const signInCognito = async (
//   user: ILoginModel,
//   dispatch: DispatchFunction,
// ): Promise<void> => {
//   dispatch({ type: ActionType.REQUEST_SIGN_IN });

//   try {
//     const userCognito = new CognitoUser({
//       Username: user.username,
//       Pool: getUserPool(),
//     });

//     const authDetails = new AuthenticationDetails({
//       Username: user.username,
//       Password: user.password,
//     });

//     userCognito.authenticateUser(authDetails, {
//       onSuccess: (data) => {
//         const idToken = data.getIdToken();
//         if (idToken) {
//           const userGuid = idToken.decodePayload().sub;
//           const newUserStatus = {
//             isError: false,
//             isLogin: true,
//             message: 'Access granted',
//             token: idToken.getJwtToken(),
//             country: 'PRI',
//             role: '',
//             guid: userGuid,
//           };

//           (async (): Promise<void> => {
//             const result = await requestUserData(userGuid);

//             if (result && result.data?.data) {
//               const isUserActive = result.data?.data?.active ?? true;

//               if (isUserActive) {
//                 dispatch({ type: ActionType.SIGN_IN_SUCCESS });
//                 dispatch({
//                   type: ActionType.SET_USER_STATUS,
//                   payload: newUserStatus,
//                 });
//               } else {
//                 dispatch({ type: ActionType.SIGN_IN_FAIL });
//                 dispatch({
//                   type: ActionType.SET_USER_STATUS,
//                   payload: {
//                     isError: true,
//                     isLogin: false,
//                     message:
//                       'Usuario inactivo. Por favor consulte a un administrativo.',
//                   },
//                 });
//               }
//             } else {
//               dispatch({ type: ActionType.SIGN_IN_FAIL });
//               dispatch({
//                 type: ActionType.SET_USER_STATUS,
//                 payload: {
//                   isError: true,
//                   isLogin: false,
//                   message: 'User does not correspond to any account',
//                 },
//               });
//             }
//           })();
//         }
//       },

//       onFailure: (err) => {
//         if (err.code === 'UserNotConfirmedException') {
//           dispatch({
//             type: ActionType.SET_USER_NEW_PASSWORD,
//             payload: {
//               confirmAccount: true,
//               isNewUser: false,
//               sessionAttr: { email: user.username },
//             },
//           });
//         } else {
//           const newUserStatus = {
//             isError: true,
//             isLogin: false,
//             message: err.message ?? 'User does not correspond to any account',
//           };
//           dispatch({ type: ActionType.SIGN_IN_FAIL });
//           dispatch({
//             type: ActionType.SET_USER_STATUS,
//             payload: newUserStatus,
//           });
//         }
//       },

//       newPasswordRequired: (userAttributes, requiredAttributes) => {
//         const newPassword = user.password;

//         userCognito.completeNewPasswordChallenge(newPassword, null, {
//           onSuccess: (session) => {
//             alert(
//               'Cuenta confirmada satisfactoriamente. Por favor vuelva a iniciar sesión.',
//             );
//             // eslint-disable-next-line no-console
//             console.info('Password changed and login successful!', session);

//             dispatch({
//               type: ActionType.SET_USER_STATUS,
//               payload: {
//                 isError: false,
//                 isLogin: false,
//                 message: 'Password changed and login successful!',
//               },
//             });

//             window.location.reload();
//           },
//           onFailure: (err) => {
//             console.error('Password change failed!', err);
//           },
//         });
//       },
//     });
//   } catch (error: any) {
//     if (!error && error.success) {
//       const newUserStatus = {
//         isError: true,
//         isLogin: false,
//         message: 'User does not correspond to any account',
//       };
//       dispatch({ type: ActionType.SIGN_IN_FAIL });
//       dispatch({ type: ActionType.SET_USER_STATUS, payload: newUserStatus });
//     }
//   }
// };

export const signUpCognito = async (
  user: ILoginModel,
  dispatch: DispatchFunction,
): Promise<void> => {
  dispatch({ type: ActionType.REQUEST_SIGN_IN });

  try {
    if (user.newPassword) {
      // const attributes = [
      //   new CognitoUserAttribute({
      //     Name: 'custom:countryCode',
      //     Value: user.country ? user.country : 'PRI',
      //   }),
      //   new CognitoUserAttribute({
      //     Name: 'custom:userRole',
      //     Value: user.role ? user.role : 'NONE',
      //   }),
      // ];
      // getUserPool().signUp(
      //   user.username,
      //   user.newPassword,
      //   attributes,
      //   [],
      //   async (err, result) => {
      //     if (err) {
      //       const newUserStatus = {
      //         isError: true,
      //         isLogin: false,
      //         message: err.message ?? 'Request to Sign Up is failed.',
      //       };
      //       dispatch({ type: ActionType.SIGN_IN_FAIL });
      //       dispatch({
      //         type: ActionType.SET_USER_STATUS,
      //         payload: newUserStatus,
      //       });
      //     } else {
      //       // Send data to SIC
      //       const data = {
      //         guidID: result?.userSub,
      //         userName: user.username,
      //         email: user.username,
      //         rolId: user.role ? +user.role : 0,
      //         companyId: user.company ? +user.company : 0,
      //         name: user.name,
      //         codPais: user.country,
      //       };
      //       const response = await APIClient.post(
      //         endpoints.POST_SUBMIT_USER,
      //         data,
      //       );
      //       if (response.data.success) {
      //         dispatch({
      //           type: ActionType.SET_USER_NEW_PASSWORD,
      //           payload: {
      //             confirmAccount: false,
      //             isNewUser: true,
      //             sessionAttr: {},
      //           },
      //         });
      //       } else {
      //         const newUserStatus = {
      //           isError: true,
      //           isLogin: false,
      //           message: 'Error to create a new user',
      //         };
      //         dispatch({ type: ActionType.SIGN_IN_FAIL });
      //         dispatch({
      //           type: ActionType.SET_USER_STATUS,
      //           payload: newUserStatus,
      //         });
      //       }
      //     }
      //   },
      // );
    }
  } catch (error: any) {
    if (!error && error.success) {
      const newUserStatus = {
        isError: true,
        isLogin: false,
        message: 'User does not correspond to any account',
      };
      dispatch({ type: ActionType.SIGN_IN_FAIL });
      dispatch({ type: ActionType.SET_USER_STATUS, payload: newUserStatus });
    }
  }
};

export const requestChangePassword = async (
  email: string,
  dispatch: DispatchFunction,
): Promise<void> => {
  if (email.length > 0) {
    // const userCognito = new CognitoUser({
    //   Username: email,
    //   Pool: getUserPool(),
    // });
    // userCognito.forgotPassword({
    //   onSuccess: (resp: any) => {
    //     // eslint-disable-next-line no-console
    //     console.log('Accounts::Recovery information is sended.');
    //     // eslint-disable-next-line no-console
    //     console.log('Info::', resp);
    //     dispatch({
    //       type: ActionType.SET_ALERT_REQUEST_PASSWORD,
    //       payload: {
    //         show: true,
    //         isError: false,
    //       },
    //     });
    //   },
    //   onFailure: (err: any) => {
    //     // eslint-disable-next-line no-console
    //     console.log('Accounts::The information cannot be sended.');
    //     // eslint-disable-next-line no-console
    //     console.log('Error::', err.message);
    //     dispatch({
    //       type: ActionType.SET_ALERT_REQUEST_PASSWORD,
    //       payload: {
    //         show: true,
    //         isError: true,
    //       },
    //     });
    //   },
    // });
    console.warn('inactive-process');
  }
};

export const renewPassword = async (
  user: ILoginModel,
  dispatch: DispatchFunction,
): Promise<void> => {
  if (user.verifyCode && user.newPassword && user.newPassword.length > 0) {
    // const userCognito = new CognitoUser({
    //   Username: user.username,
    //   Pool: getUserPool(),
    // });

    // userCognito.confirmPassword(user.verifyCode, user.newPassword, {
    //   onSuccess() {
    //     dispatch({
    //       type: ActionType.SET_ALERT_CHANGE_PASSWORD,
    //       payload: {
    //         show: true,
    //         isError: false,
    //       },
    //     });
    //   },
    //   onFailure(err) {
    //     // eslint-disable-next-line no-console
    //     console.log('Error::', err.message);

    //     dispatch({
    //       type: ActionType.SET_ALERT_CHANGE_PASSWORD,
    //       payload: {
    //         show: true,
    //         isError: true,
    //       },
    //     });
    //   },
    // });
    console.warn('inactive-process');
  } else {
    // eslint-disable-next-line no-console
    console.log('Accounts::Missing user information for confirm the data.');
  }
};

export const activeUserWithCode = async (
  code: string,
  username: string,
  dispatch: DispatchFunction,
): Promise<void> => {
  dispatch({ type: ActionType.REQUEST_SIGN_IN });

  try {
    console.warn('inactive-process');
    // const userCognito = new CognitoUser({
    //   Username: username,
    //   Pool: getUserPool(),
    // });

    // userCognito.confirmRegistration(code, true, (err) => {
    //   if (err) {
    //     const newUserStatus = {
    //       isError: true,
    //       message: err.message ?? 'Request to Sign Up is failed.',
    //     };
    //     dispatch({ type: ActionType.SIGN_IN_FAIL });
    //     dispatch({
    //       type: ActionType.SET_USER_STATUS,
    //       payload: newUserStatus,
    //     });
    //   } else {
    //     const newUserStatus = {
    //       isError: false,
    //       isLogin: true,
    //       message: 'REQUEST_LOGIN',
    //     };

    //     dispatch({ type: ActionType.SIGN_IN_SUCCESS });
    //     dispatch({
    //       type: ActionType.SET_USER_STATUS,
    //       payload: newUserStatus,
    //     });
    //   }
    // });
  } catch (error: any) {
    if (!error && error.success) {
      const newUserStatus = {
        isError: true,
        isLogin: false,
        message: 'User does not correspond to any account',
      };
      dispatch({ type: ActionType.SIGN_IN_FAIL });
      dispatch({ type: ActionType.SET_USER_STATUS, payload: newUserStatus });
    }
  }
};

export const getCurrentSession = async (
  userState: IUserStatusModel,
  dispatch: DispatchFunction,
): Promise<any | null> => {
  let currentUser = await getCurrentCognitoUser();
  const oneAccessToken = localStorage.getItem('oneAccessToken');
  const impersonatedUserData = oneAccessToken
    ? await handleImpersonatedUserData(oneAccessToken)
    : null;

  if (!impersonatedUserData) {
    if (userState.guid?.length > 0) {
      const response = await requestUserData(userState.guid);

      if (response.data && response.data.success) {
        await handleUserData(response.data?.data, dispatch, userState);
      }
    }
  } else {
    currentUser = { ...currentUser, isAuthenticate: true };
    await handleUserData(impersonatedUserData, dispatch, userState, true);
  }

  return currentUser;
};

// export const getUserAttributes = async (): Promise<any> =>
//   await new Promise((resolve, reject) => {
//     const currentUser = getUserPool().getCurrentUser();
//     if (currentUser) {
//       currentUser.getSession(async (err, session) => {
//         if (err) {
//           reject();
//         } else {
//           const attributes: CognitoUserAttribute[] = await new Promise(
//             (resolve, reject) => {
//               currentUser.getUserAttributes((err, attributes) => {
//                 if (err) {
//                   reject(err);
//                 } else {
//                   const results = [];

//                   if (attributes) {
//                     for (const attribute of attributes) {
//                       const { Name, Value } = attribute;
//                       results[Name] = Value;
//                     }
//                   }

//                   resolve(results);
//                 }
//               });
//             },
//           );

//           resolve({
//             ...session,
//             ...attributes,
//           });
//         }
//       });
//     } else {
//       reject();
//     }
//   });

export const createOrUpdateUser = async (
  user: IUserModel,
  dispatch: DispatchFunction,
): Promise<void> => {
  try {
    const data: IUserModel = {
      guidID: user.guidID,
      userCompanyID: user.userCompanyID,
      userName: user.email,
      rolId: user.rolId,
      companyId: user.companyId,
      name: user.name,
      email: user.email,
      active: user.active,
      runnerCode:
        user.rolId === Rol.BROKER ||
        user.rolId === Rol.PRE_BROKER ||
        user.rolId === Rol.PRE_CONSULTANT
          ? user.runnerCode
          : '',
      vendorID: user.vendorID,
      customerCode: user.rolId === Rol.CUSTOMER ? user.customerCode : '',
      isNewUser: user.isNewUser,
      isMultiCountry: user.isMultiCountry,
      companyIdList: user.companyIdList,
      countryIdList: user.countryIdList,
    };

    const sendData = (currentData: any) => {
      (async (): Promise<void> => {
        await APIClient.post(endpoints.POST_SUBMIT_USER, currentData)
          .then((response: any) => {
            if (response.data.success) {
              dispatch({
                type: ActionType.SET_ALERT_NEW_USER,
                payload: {
                  show: true,
                  message: '',
                  isError: false,
                },
              });
            } else {
              dispatch({
                type: ActionType.SET_ALERT_NEW_USER,
                payload: {
                  show: true,
                  message: '',
                  isError: true,
                },
              });
            }
          })
          .catch((err: any) => {
            dispatch({
              type: ActionType.SET_ALERT_NEW_USER,
              payload: {
                show: true,
                message:
                  err.response?.data?.error?.code === 'AliasExistsException'
                    ? 'El correo ya se encuentra en uso, por favor intente utilizar un correo diferente y válido.'
                    : '',
                isError: true,
              },
            });
          });
      })();
    };

    if (user.isNewUser && user.password && user.password.length > 0) {
      // getUserPool().signUp(
      //   user.email,
      //   user.password,
      //   [],
      //   [],
      //   async (err: any, result: any) => {
      //     if (err) {
      //       //Send Error
      //       // eslint-disable-next-line no-console
      //       console.log('Accounts::The information cannot be submited.');
      //       // eslint-disable-next-line no-console
      //       console.log('Error::', err);

      //       dispatch({
      //         type: ActionType.SET_ALERT_NEW_USER,
      //         payload: {
      //           show: true,
      //           message:
      //             err.code === 'UsernameExistsException'
      //               ? 'El correo ya se encuentra en uso, por favor intente utilizar un correo diferente y válido.'
      //               : '',
      //           isError: true,
      //         },
      //       });
      //     } else {
      //       // Send data to SIC
      //       // eslint-disable-next-line no-console
      //       console.log('Accounts::User information is sended.');
      //       // eslint-disable-next-line no-console
      //       console.log('Info::', result);

      //       const updateData = { ...data, guidID: result?.userSub };
      //       sendData(updateData);
      //     }
      //   },
      // );
      console.warn('inactive-process');
    } else {
      sendData(data);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getUsersByFilter = async (
  type: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  queryFilters: any,
): Promise<IUserModel[] | undefined> => {
  try {
    const data: IUserModel[] = [];
    let uri = endpoints.GET_ALL_USERS.replace('{type}', type) + '?';

    Array.from(queryFilters).map((f: any) => {
      uri += f.name + '=' + f.value + '&';

      return uri;
    });

    const response = await APIClient.get(uri);
    Array.from(response?.data?.data).map((d: any) => {
      const modelData: IUserModel = {
        userCompanyID: d.userCompanyID,
        guidID: d.guidID,
        userName: d.userName,
        rolId: d.rolId,
        rolName: d.rolName,
        rolDescription: d.rolDescription,
        companyId: d.companyId,
        companyName: d.companyName,
        email: d.email,
        active: d.active,
        name: d.name,
        lastName: d.name,
        vendorID: d.vendorID,
        currentCount: d.currentCount,
        isMultiCountry: d.isMultiCountry,
      };

      data.push(modelData);

      return true;
    });

    return data;
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log('ERROR: ' + ex);

    return [];
  }
};

export const getUserByGuid = async (
  guid: string,
): Promise<IUserModel | undefined> => {
  try {
    const uri = endpoints.GET_USER.replace('{guid}', guid);
    const response = await APIClient.get(uri);

    if (response?.data?.success) {
      const d = response?.data?.data;
      const modelData: IUserModel = {
        userCompanyID: d.userCompanyID,
        guidID: d.guidID,
        userName: d.userName,
        rolId: d.rolId,
        rolName: d.rolName,
        rolDescription: d.rolDescription,
        companyId: d.companyId,
        companyName: d.companyName,
        runnerCode: d.runnerCode,
        customerCode: d.customerCode,
        email: d.email,
        active: d.active,
        name:
          (d.name + '').length > 1
            ? (d.name + '').split(' ')[0] ?? d.name
            : d.name,
        lastName:
          (d.name + '').length > 1 ? (d.name + '').split(' ')[1] ?? '' : '',
        vendorID: d.vendorID,
        currentCount: d.currentCount,
        companyIdList: d.companyIdList,
        countryIdList: d.countryIdList,
        isMultiCountry: d.isMultiCountry,
        codPais: d.codPais,
      };

      return modelData;
    }

    return undefined;
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log('ERROR: ' + ex);

    return undefined;
  }
};

// Get available companies
export const getCompanies = async (): Promise<any> => {
  localStorage.setItem('companies', '');
  const uri = endpoints.GET_COMPANIES.replace('{countryCode}', 'ALL');
  const resultCompanies = await APIClient.get(uri);
  const dataCompanies = resultCompanies?.data?.data;

  localStorage.setItem('companies', JSON.stringify(dataCompanies));
};

// Get available provinces / cantons / districts
export const getLocationsCatalog = async (
  countryCode: string,
): Promise<any> => {
  localStorage.setItem('locations', '');
  if (countryCode) {
    const uriLocations = endpoints.GET_LOCATIONS.replace(
      '{countryCode}',
      countryCode,
    );
    const resultLocations = await APIClient.get(uriLocations);
    localStorage.setItem(
      'locations',
      JSON.stringify(resultLocations?.data?.data),
    );
  }
};

// Get available inspectors
export const getInspectorsCatalog = async (
  countryCode: string,
): Promise<any> => {
  localStorage.setItem('inspectors', '');
  if (countryCode) {
    const p = { countryCode: countryCode };
    const resultInspectors = await APIClient.get(endpoints.GET_INSPECTORS, {
      params: p,
    });
    const dataInspectors = resultInspectors?.data?.data;
    localStorage.setItem('inspectors', JSON.stringify(dataInspectors));
  }
};

// Get available user permissions
export const getUserPermissions = async (
  countryId: number,
  companyId: number,
  roleId: number,
): Promise<any> => {
  const response = await APIClient.get(endpoints.GET_USER_PERMISSIONS, {
    params: {
      companyId: companyId,
      roleId: roleId,
      countryId: countryId,
    },
  });

  const permissions = response?.data?.data;
  localStorage.setItem('permissions', JSON.stringify(permissions));
};

// Get available tags by company
export const getTagsByCompany = async (companyId: string): Promise<any> => {
  localStorage.setItem('tags', '');
  if (companyId) {
    const tagsUrl = endpoints.GET_COMPANY_TAGS.replace(
      '{companyId}',
      companyId,
    );
    const companyTags = await APIClient.get(tagsUrl);
    localStorage.setItem(
      'tags',
      JSON.stringify(companyTags?.data?.data?.response),
    );
  }
};

// Get available agencies for preinspections
export const getAgencies = async (countryCode: string): Promise<any> => {
  localStorage.setItem('agencies', '');
  if (countryCode && countryCode === CountryCode.PAN) {
    const p = { countryCode: countryCode };
    const resultAgencies = await APIClient.get(endpoints.GET_AGENCIES, {
      params: p,
    });
    const dataRunners = resultAgencies?.data?.data;
    localStorage.setItem('agencies', JSON.stringify(dataRunners));
  }
};

// Get list a drivers from user provider
export const getDriversByUser = async (
  userId: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  queryFilters: any,
): Promise<IDriverModel[] | undefined> => {
  try {
    const data: IDriverModel[] = [];
    let uri = endpoints.GET_USER_DRIVERS.replace('{userId}', userId) + '?';

    Array.from(queryFilters).forEach((f: any) => {
      uri += f.name + '=' + f.value + '&';
    });

    const response = await APIClient.get(uri);
    Array.from(response?.data?.data).forEach((d: any) => {
      const modelData: IDriverModel = {
        driverId: d.driverId,
        driverCode: d.driverCode,
        isActive: d.isActive,
        currentCount: d.currentCount,
      };

      data.push(modelData);
    });

    return data;
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log('ERROR: ' + ex);

    return [];
  }
};

// Add or update user driver
export const createOrUpdateDriver = async (
  userId: string,
  model: IDriverModel,
): Promise<any> => {
  try {
    const uri = endpoints.POST_USER_DRIVER.replace('{userId}', userId);

    const response = await APIClient.post(uri, model).catch((ex) => {
      return ex.response.data;
    });

    return response;
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log('ERROR: ' + ex);

    return [];
  }
};

// Inactive driver from user provider
export const inactiveDriver = async (driverId: number): Promise<any> => {
  try {
    const uri = endpoints.DELETE_USER_DRIVER.replace(
      '{driverId}',
      driverId.toString(),
    );
    const response = await APIClient.delete(uri);

    return response;
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log('ERROR: ' + ex);

    return [];
  }
};

// Get available runners
export const getRunnerOwners = async (): Promise<any> => {
  localStorage.setItem('runners', '');
  const resultOwners = await APIClient.get(endpoints.GET_RUNNEROWNERS);
  const dataRunners = resultOwners?.data?.data;

  localStorage.setItem('runners', JSON.stringify(dataRunners));
};

const handleUserData = async (
  userData,
  dispatch,
  userState,
  isOneAccess = false,
) => {
  const codPais =
    (userData.isMultiCountry
      ? userData.currentCountryCode
      : userData.codPais) || '';
  const countryId =
    (userData.isMultiCountry
      ? userData.currentCountryId
      : userData.countryId) || 0;
  const companyId = userData.companyId || 0;
  const userCompanyID = userData.userCompanyID || 0;
  const runnerCode = userData.runnerCode || '';
  const rolId = userData.rolId || 0;

  localStorage.setItem('countryId', countryId);
  localStorage.setItem('countryCode', codPais);
  localStorage.setItem('userId', userCompanyID);
  localStorage.setItem('companyId', userData.companyId || 0);
  localStorage.setItem('rolId', rolId);
  localStorage.setItem('currentUser', JSON.stringify(userData));
  localStorage.setItem('inspectorId', userData.userInspectorID || 0);
  localStorage.setItem('runnerCode', runnerCode);
  localStorage.setItem('customerCode', userData.customerCode || '');
  localStorage.setItem('preInspectionLink', userData.preInspectionLink || '');
  localStorage.setItem('companyIdList', userData.companyIdList || '');
  localStorage.setItem('isMultiCountry', userData.isMultiCountry || 'false');
  localStorage.setItem('currentCountryCode', userData.currentCountryCode || '');

  // Get available companies
  await getCompanies();

  // Get available inspectors
  await getInspectorsCatalog(codPais);

  // Get available provinces / cantons / districts
  await getLocationsCatalog(codPais);

  // Get available user permissions
  await getUserPermissions(countryId, companyId, rolId);

  // Get available tags
  await getTagsByCompany(String(companyId));

  // Get available agencies
  await getAgencies(codPais);

  // Get available runner owners
  if (
    (runnerCode && String(runnerCode).length > 0) ||
    Rol.ADMIN === rolId ||
    Rol.ADJUSTER === rolId
  ) {
    await getRunnerOwners();
  }

  const newState = {
    ...userState,
    country: codPais,
    role: +rolId,
    userId: +userCompanyID,
  };

  if (isOneAccess) {
    newState.isLogin = true;
    newState.isOneAccess = true;
    dispatch({ type: ActionType.SIGN_IN_SUCCESS });
  }

  dispatch({ type: ActionType.SET_USER_STATUS, payload: newState });
  dispatch({
    type: ActionType.SET_CURRENT_USER,
    payload: userData,
  });
};

const handleImpersonatedUserData = async (
  oneAccessToken: string,
): Promise<IUserModel> => {
  const decodedPayload = JSON.parse(
    Buffer.from(oneAccessToken.split('.')[1], 'base64').toString('utf-8'),
  );
  const response = await requestUserData(decodedPayload.impersonatedUserGuid);

  const impersonatedUserData: IUserModel = {
    guidID: decodedPayload.impersonatedUserGuid,
    userName: decodedPayload.email,
    email: decodedPayload.email,
    name: decodedPayload.name,
    lastName: decodedPayload.lastName,
    runnerCode: decodedPayload.profileCode,
    userCompanyID: response.data?.data.userCompanyID,
    companyId: response.data?.data.companyId,
    countryId: response.data?.data.countryId,
    rolId: response.data?.data.rolId,
    rolName: response.data?.data.rolName,
    codPais: response.data?.data.codPais,
    active: response.data?.data.active,
    isMultiCountry: response.data?.data.isMultiCountry,
  };

  return impersonatedUserData;
};

export const getUserInitialScreen = (): string => {
  let screenPath = '/in-progress';
  const rolId = localStorage.getItem('rolId');
  if (
    rolId === Rol.PRE_ADMIN.toString() ||
    rolId === Rol.PRE_INSURER.toString() ||
    rolId === Rol.PRE_BROKER.toString() ||
    rolId === Rol.PRE_CONSULTANT.toString()
  ) {
    screenPath = '/preinspections';
  } else if (
    rolId === Rol.ADMIN.toString() ||
    rolId === Rol.ADJUSTER.toString() ||
    rolId === Rol.INSURER.toString() ||
    rolId === Rol.BROKER.toString() ||
    rolId === Rol.INSPECTOR.toString() ||
    rolId === Rol.AUDIT.toString() ||
    rolId === Rol.CUSTOMER.toString()
  ) {
    if (
      CmtCountries.includes(localStorage.getItem('countryCode') as CountryCode)
    ) {
      screenPath = PATH_ROUTES.EVENTS_CLAIMS;
    } else {
      screenPath = PATH_ROUTES.EVENTS;
    }
  }

  return screenPath;
};

export const verifyOneAccessToken = async (token: string): Promise<any> => {
  return await APIClient.post(endpoints.POST_ACCOUNTS_ONEACCESS_VERIFY, {
    token: token,
  });
};

export const updateCurrentCountryUser = async (
  countryCode: string,
): Promise<void> => {
  const endpoint = endpoints.PATCH_USERS_CURRENT_COUNTRY.replace(
    '{userId}',
    localStorage.getItem('userId') || '',
  ).replace('{countryCode}', countryCode);

  return await APIClient.patch(endpoint);
};
