import Alert from '@mui/material/Alert';
import clsx from 'clsx';
import Loader from 'components/common/loader/Loader';
import ValidationLabel from 'components/common/validation-label';
import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import style from './override-mui-styles';
import './styles.scss';
import {
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
} from '@mui/material';
import {
  IUserContext,
  UserContext,
  activeUserWithCode,
  claimsAuth,
  getCurrentCognitoUser,
  getUserInitialScreen,
} from 'context/user';

interface VerificationCodeProps {
  style: any;
  open: boolean | false;
  onClose: () => void;
  onSendCode: (code: string) => void;
}

const VerificationCode = (props: VerificationCodeProps) => {
  const { handleSubmit, register, errors } = useForm();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmitCode = (data: any) => {
    props.onSendCode(data);
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={props.open}
    >
      <form
        className={'form'}
        onSubmit={handleSubmit(handleSubmitCode)}
        noValidate
      >
        <DialogTitle id="dialog-title">Confirmar Cuenta Connect</DialogTitle>
        <DialogContent>
          <Typography
            component="h4"
            variant="h4"
            className={clsx('description', props.style.description)}
          >
            Digite el código enviado a tu correo:
          </Typography>
          <TextField
            error={errors.code !== undefined}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="code"
            label="Código de verificación"
            name="code"
            autoComplete="code"
            autoFocus
            inputRef={register({
              required: 'Código de verificación es requerido',
            })}
          />
          {errors.code && (
            <ValidationLabel message={errors.code.message} isError={true} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button type="submit" color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const SignIn = (): JSX.Element => {
  const overrideMuiClasses = style();
  const currentHistory = useHistory();
  const redirectTo = currentHistory.location.search.split('redirectTo=')[1];

  const { state, dispatch } = useContext<IUserContext>(UserContext);
  const { loading, userSession, currentUserStatus } = state;

  const { handleSubmit, register, errors, getValues } = useForm();
  const [openVerificationCode, setOpenVerificationCode] = React.useState(false);

  const onSubmit = useCallback(
    (data: any) => {
      claimsAuth(data, dispatch);
    },
    [dispatch],
  );

  const handleCloseModalCode = () => {
    setOpenVerificationCode(false);
  };

  const handleSendModalCode = (data: any) => {
    activeUserWithCode(
      data.code,
      state?.userSession?.sessionAttr?.email,
      dispatch,
    );
    handleCloseModalCode();
  };

  const validateUserSession = useCallback(() => {
    (async (): Promise<void> => {
      const { isAuthenticate, sessionExpired } = await getCurrentCognitoUser();

      if (isAuthenticate && !sessionExpired) {
        currentHistory.push(redirectTo || getUserInitialScreen());
      }
    })();
  }, [dispatch, currentHistory, currentUserStatus, redirectTo]);

  React.useEffect(() => {
    // User Session
    if (!currentUserStatus.role) {
      validateUserSession();
    }

    if (currentUserStatus.isLogin && currentUserStatus.token) {
      currentHistory.push(redirectTo || getUserInitialScreen());
    } else if (
      currentUserStatus.isLogin &&
      currentUserStatus.message === 'REQUEST_LOGIN'
    ) {
      onSubmit({
        username: getValues('username'),
        password: getValues('password'),
      });
    } else if (userSession.confirmAccount) {
      setOpenVerificationCode(true);
    }
  }, [
    userSession,
    currentUserStatus,
    currentHistory,
    setOpenVerificationCode,
    onSubmit,
    getValues,
    validateUserSession,
    redirectTo,
  ]);

  return (
    <>
      <Loader isActive={loading} />
      <Typography
        className={clsx('title', overrideMuiClasses.title)}
        component="h1"
        variant="h2"
      >
        BIENVENIDO
      </Typography>
      <Typography
        className={clsx('subtitle', overrideMuiClasses.subtitle)}
        component="h1"
        variant="h4"
      >
        Ingrese a su cuenta
      </Typography>
      <form className={'form'} onSubmit={handleSubmit(onSubmit)} noValidate>
        {currentUserStatus.isError && (
          <Alert severity="error">{currentUserStatus.message}</Alert>
        )}
        <TextField
          error={errors.username !== undefined}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Usuario"
          name="username"
          autoComplete="username"
          autoFocus
          inputRef={register({ required: 'Usuario es requerido' })}
          InputProps={{
            classes: {
              input: overrideMuiClasses.loginTextField,
            },
          }}
        />
        {errors.username && (
          <ValidationLabel message={errors.username.message} isError={true} />
        )}
        <TextField
          error={errors.password !== undefined}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
          inputRef={register({ required: 'Contraseña es requerida' })}
          InputProps={{
            classes: {
              input: overrideMuiClasses.loginTextField,
            },
          }}
        />
        {errors.password && (
          <ValidationLabel message={errors.password.message} isError={true} />
        )}
        <Grid container>
          <Grid item xs>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recuerdame"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              className={'passwordRestoreLabel'}
              control={
                <Link
                  className={overrideMuiClasses.passwordRestoreLink}
                  href="/request-access"
                  variant="body2"
                >
                  ¿Olvidó contraseña?
                </Link>
              }
              label=""
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={overrideMuiClasses.submit}
        >
          Ingresar
        </Button>
      </form>

      {openVerificationCode && (
        <VerificationCode
          open={openVerificationCode}
          style={overrideMuiClasses}
          onSendCode={handleSendModalCode}
          onClose={handleCloseModalCode}
        />
      )}
    </>
  );
};

export default SignIn;
